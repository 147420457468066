.feature2-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  font-family: "Arial", sans-serif;
  background-color: #e6f7e6;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.feature2-title {
  font-size: 2em;
  color: #333;
  margin-bottom: 20px;
}

.feature2-text {
  font-size: 1.1em;
  color: #555;
  line-height: 1.6;
  margin-bottom: 15px;
}

.feature2-text:last-child {
  margin-bottom: 0;
}
