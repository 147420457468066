/* Card.css */
.home-whyChooseUsSection {
  background-color: #e6f7e6;/* Navy background */
  color: #050505; /* White text color */
  padding: 20px;
  border-radius: 8px;
  text-align: center;
}

.home-section-title {
  font-size: 2rem;
  margin-bottom: 10px;
}

.home-section-description {
  font-size: 1rem;
  margin-bottom: 20px;
  display: block;
}

.home-cards-container {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}

.home-card {
  background-color: #6dddb2; /* White background for cards */
  color: #001f3f; /* Navy text color for cards */
  border-radius: 8px;
  padding: 20px;
  margin: 10px;
  width: 200px; /* Adjust width as needed */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: left;
}

.home-card-icon {
  height: 50px;
  width: 50px;
  margin-bottom: 10px;
}

.home-card-img {
  height: 100%;
  width: 100%;
}

.home-card strong {
  display: block;
  font-size: 1.2rem;
  margin-bottom: 10px;
}

.home-card p {
  font-size: 0.9rem;
}
