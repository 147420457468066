.quoteFormContainer {
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  background-color:  #e6f7e6;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.quoteH1 {
  text-align: center;
  margin-bottom: 20px;
}

.formSubmissionThanks {
  margin-top: 20px;
  padding: 20px;
  background-color: #f8f9fa;
  border: 1px solid #e9ecef;
  border-radius: 5px;
}

.formSubmissionThanks h3 {
  margin: 0;
  font-size: 1.5rem;
  color: #28a745;
}

.formSubmissionThanks p {
  margin: 5px 0 0;
  font-size: 1rem;
  color: #6c757d;
}

.form-label {
  font-weight: bold;
}

.form-control {
  margin-bottom: 10px;
}

.submitQuoteBtn {
  display: block;
  width: 40%;
  padding: 10px;
  margin-left: auto;
  background-color: #4caf50;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.submitQuoteBtn:hover {
  background-color: #7aaadd;
}
