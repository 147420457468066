.instagram-feed {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    gap: 16px;
    padding: 20px;
    justify-content: center;
  }
  
  .instagram-post {
    background: #fff;
    border-radius: 12px;
    box-shadow: 0px 6px 15px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    transition: all 0.3s ease-in-out;
    position: relative;
  }
  
  .instagram-post:hover {
    transform: translateY(-5px);
    box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.15);
  }
  
  .instagram-post img,
  .instagram-post video {
    width: 100%;
    height: auto;
    border-radius: 12px;
    object-fit: cover; /* Ensures proper cropping */
  }


  .instagram-post a {
    display: block;
    position: relative;
    text-decoration: none;
  }
  
  .instagram-caption {
    position: absolute;
    bottom: 0;
    width: 100%;
    background: rgba(0, 0, 0, 0.6);
    color: white;
    font-size: 14px;
    padding: 8px;
    text-align: center;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
  }
  
  .instagram-post:hover .instagram-caption {
    opacity: 1;
  }
  
  /* Load More Button */
  .load-more {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px auto;
    padding: 12px 20px;
    font-size: 16px;
    background: linear-gradient(135deg, #ff416c, #ff4b2b);
    color: white;
    border: none;
    cursor: pointer;
    border-radius: 25px;
    transition: all 0.3s ease-in-out;
  }
  
  .load-more:hover {
    background: linear-gradient(135deg, #ff4b2b, #ff416c);
    transform: scale(1.05);
  }
  
  /* Swiper Pagination */
  .swiper-pagination-bullet {
    background: white !important;
    opacity: 0.7;
    width: 8px;
    height: 8px;
    margin: 0 5px !important;
    transition: opacity 0.3s ease-in-out;
  }
  
  .swiper-pagination-bullet-active {
    background: #ff416c !important;
    opacity: 1;
    transform: scale(1.2);
  }
  @media (max-width: 768px) {
    .instagram-feed {
      grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    }
  
    .swiper-slide img {
      max-height: 250px;
      object-fit: cover;
    }
  }
  