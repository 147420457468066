/* General body styling */
.quoteBody {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  color: #333; /* Dark grey text for better readability */
  background-color: #e6f7e6;/* Soft light blue background */
}

/* Header styling */
.quoteHeader {
  text-align: center; /* Centers the header text */
  margin-bottom: 20px; /* Adds space below the header */
}

.quoteHeader hr {
  margin-top: 10px;
  margin-bottom: 20px;
  border: none;
  height: 1px;
  background-color: #d3d3d3; /* Light grey horizontal rule */
}

.quoteTitle {
  color: #27496d; /* Dark blue color for the title, suggesting reliability */
  margin-bottom: 10px;
}

/* Contact details and form container */
.quoteMain {
  display: flex;
  justify-content: space-between; /* Distributes space between children */
  align-items: flex-start; /* Aligns items at the start of the flex container */
  gap: 20px; /* Space between columns */
  background-color: #e6f7e6; /* Soft light blue background */
  padding: 15px;
  border-radius: 8px; /* Rounded corners */
  box-shadow: 0 2px 5px rgba(0,0,0,0.15); /* Enhanced shadow for depth */
}

/* Left column for contact details */
.contactDetails {
  flex: 1; /* Takes up half of the space */
  padding: 20px;
  background-color: #e6e9ef; /* Light grayish-blue for a subtle contrast */
  border-right: 2px solid #d3d3d3; /* Adds a subtle border between the columns */
}

/* Right column for the form */
.formContainer {
  flex: 1; /* Takes up half of the space */
  padding: 20px;
  background-color: #e6e9ef; /* Consistent background for cohesion */
}

/* Image styling */
.manPaintingQuoteImg {
  width: 100%; /* Full width within its container */
  max-width: 500px; /* Maximum width */
  height: auto; /* Maintain aspect ratio */
  display: block; /* Removes bottom space/gap */
  margin: 10px auto 20px; /* Center the image horizontally and add space below */
  align-items: center;
  
}

/* Contact icons and links styling */
.contactInfoQuote {
  display: flex;
  flex-direction: column; /* Stacks contact methods vertically */
  align-items: center; /* Center aligns items for better visual alignment */
  gap: 15px; /* Space between contact methods */
}

.contactInfoQuote a {
  display: flex;
  align-items: center;
  text-decoration: none; /* No underlines on links */
  color: #27496d; /* Echoing the title color for consistency */
}

.contactInfoQuote a:hover {
  text-decoration: underline; /* Underline on hover for interaction feedback */
}

/* Footer styling */
.quoteFooter {
  font-size: 0.9em; /* Slightly smaller text for the footer */
  color: #666; /* Lighter text color for less emphasis */
  text-align: center; /* Center-aligns footer text */
  margin-top: 20px; /* Space above the footer */
  padding-top: 10px;
  border-top: 1px solid #d3d3d3; /* Top border line */
}

/* Responsive adjustments for mobile */
@media (max-width: 768px) {
  .quoteMain {
      flex-direction: column; /* Stack vertically on small screens */
      padding: 20px; /* Add padding for mobile readability */
  }

  .contactDetails, .formContainer {
      width: 100%; /* Each section takes full width */
      border-right: none; /* Remove border that only makes sense in a side-by-side layout */
      padding: 20px 0; /* Adjust padding to remove horizontal space */
      box-shadow: none; /* Optionally remove shadow to keep it clean on mobile */
      margin-bottom: 20px; /* Add space between the sections */
  }

  .formContainer {
      display: flex;
      flex-direction: column;
      align-items: center; /* Center align the items for better mobile experience */
  }

  /* Ensure inputs and text areas are not too wide */
  input[type="text"], input[type="email"], input[type="tel"], textarea {
      width: calc(100% - 40px); /* Full width minus padding for inputs */
      margin: 0 auto; /* Center align the inputs */
      box-sizing: border-box; /* Include padding and border in the element's width */
  }

  /* Centering the Submit button */
  input[type="submit"], button {
      display: block; /* Make the submit button a block element */
      width: auto; /* Auto width to fit content */
      margin: 20px auto; /* Center button horizontally */
  }

  /* Adjusts form spacing and alignment */
  .EmailSend { /* Assuming EmailSend is the class or add your specific class or id */
      width: 100%; /* Full width */
      padding: 0 20px; /* Padding to not touch the edges */
      box-sizing: border-box; /* Include padding in width calculation */
  }
}

/* Additional styling for all devices to improve layout */
.EmailSend {
  max-width: 500px; /* Max width for the form on larger screens */
  margin: 0 auto; /* Centering the form on all screen sizes */
}

